<template>
  <v-card>
    <v-card-title class="pt-0 pb-0">
      <span>Listagem</span>

      <v-spacer />

      <v-text-field
        v-model="filterData"
        label="Buscar por nome"
        class="mt-6 mr-4"
        outlined
        dense
        @keyup.enter="findFilterResult()"
      />

      <v-btn
        color="info"
        class="mr-4"
        outlined
        @click="openModal('create')"
      >
        <v-icon class="mr-2">
          {{ icons.mdiPlaylistPlus }}
        </v-icon>
        <span>Novo</span>
      </v-btn>

      <v-btn
        color="secondary"
        outlined
        @click="updateTable()"
      >
        <v-icon
          class="mr-2"
        >
          {{ icons.mdiFilterRemove }}
        </v-icon>
        <span>Limpar filtro</span>
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="listOfFilteredItems"
      :loading="isLoading"
      loading-text="Carregando dados..."
    >
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template v-slot:item.edit="{ item }">
        <v-icon
          color="warning"
          class="mr-1"
          @click="openModal('details', item)"
        >
          {{
            icons.mdiEye
          }}
        </v-icon>
      </template>

      <template v-slot:item.delete="{ item }">
        <v-icon
          color="error"
          class="mr-1"
          @click="deleteRegister(endpointDelete, item.id)"
        >
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showModalDetails"
      width="550px"
    >
      <SystemDetails
        :key="dataDetails.id"
        :data="dataDetails"
        @updatedTable="updateTable()"
        @close="showModalDetails = false"
      ></SystemDetails>
    </v-dialog>

    <v-dialog
      v-model="showModalCreate"
      width="550px"
    >
      <SystemModal
        @updatedTable="updateTable()"
        @close="showModalCreate = false"
      ></SystemModal>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiEye,
  mdiFilterRemove, mdiPlaylistPlus, mdiTrashCanOutline,
} from '@mdi/js'
import SystemDetails from './SystemDetails.vue'
import SystemModal from './systemModal.vue'

export default {
  components: {
    SystemModal,
    SystemDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',
      endpointDelete: '/api/v1/records/system/destroy',

      headers: [
        {
          text: 'SISTEMAS', value: 'system', sortable: false, align: 'center',
        },
        {
          text: 'ATIVO', value: 'active', sortable: false, align: 'center',
        },
        {
          text: 'DATA DE CRIAÇÃO', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'EDITAR', value: 'edit', sortable: false, align: 'center',
        },
        {
          text: 'EXCLUIR', value: 'delete', sortable: false, align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      dataDetails: {},

      isLoading: false,
      showModalCreate: false,
      showModalDetails: false,

      icons: {
        mdiEye, mdiPlaylistPlus, mdiFilterRemove, mdiTrashCanOutline,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getSystems()
    this.$on('updatedTable', this.updateTable)
  },

  methods: {
    async getSystems() {
      this.isLoading = true

      await axiosIns.get('/api/v1/records/system/index')
        .then(response => {
          this.itemsTable = response.data.data
          this.listOfFilteredItems = response.data.data
          this.sortSystemsByName()
        }).catch(error => {
          this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
        }).finally(() => {
          this.isLoading = false
        })
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({
        system,
      }) => {
        const lowerSystem = system.toLowerCase()

        return (
          lowerSystem.includes(filterDataLower)
        )
      })
    },

    sortSystemsByName() {
      this.itemsTable.sort((a, b) => a.system.localeCompare(b.system))
    },

    updateTable() {
      this.getSystems()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => { this.showModalCreate = true },
        details: () => {
          this.showModalDetails = true
          this.dataDetails = data
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
