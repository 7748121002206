import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-center mb-2"},[_vm._v(" Cadastrar novo sistema ")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Nome do Sistema","outlined":"","dense":""},model:{value:(_vm.systemName),callback:function ($$v) {_vm.systemName=$$v},expression:"systemName"}}),_c(VSwitch,{staticClass:"mt-0 ml-2",attrs:{"label":"Ativo?","color":"success","inset":"","dense":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}),_c('div',{staticClass:"d-flex justify-end mt-5"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"ml-5",attrs:{"color":"info"},on:{"click":function($event){return _vm.sendModalData()}}},[(!_vm.isLoading)?_c('span',[_vm._v("Enviar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }