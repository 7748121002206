<template>
  <v-card>
    <v-card-title class="d-flex justify-center mb-2">
      Cadastrar novo sistema
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="systemName"
        label="Nome do Sistema"
        outlined
        dense
      />

      <v-switch
        v-model="isActive"
        label="Ativo?"
        color="success"
        class="mt-0 ml-2"
        inset
        dense
      />

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      isLoading: false,

      systemName: '',
      isActive: false,
    }
  },

  methods: {
    async sendModalData() {
      const { systemName, isActive } = this
      this.isLoading = true

      const body = {
        system: systemName,
        active: isActive,
      }

      if (systemName === '') {
        this.showMessage({ title: 'Formulário inválido', text: 'O nome do sistema não pode estar vazio', icon: 'error' })
        this.isLoading = false

        return
      }

      await axiosIns.post('api/v1/records/system/store', body).then(() => {
        this.updatedTable()
        this.showMessage({ title: 'Sucesso', text: 'Cadastrado com sucesso', icon: 'success' })
      }).catch(() => {
        this.showMessage({ title: 'Erro ao enviar dados', text: 'contate a equipe de desenvolvimento', icon: 'error' })
      }).finally(() => {
        this.isLoading = false
        this.closeModal()

        this.systemName = ''
        this.isActive = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
