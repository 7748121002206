import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"pt-0 pb-0"},[_c('span',[_vm._v("Listagem")]),_c(VSpacer),_c(VTextField,{staticClass:"mt-6 mr-4",attrs:{"label":"Buscar por nome","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findFilterResult()}},model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openModal('create')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_c('span',[_vm._v("Novo")])],1),_c(VBtn,{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.updateTable()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemove)+" ")]),_c('span',[_vm._v("Limpar filtro")])],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.isLoading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.openModal('details', item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-1",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpointDelete, item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}}])}),_c(VDialog,{attrs:{"width":"550px"},model:{value:(_vm.showModalDetails),callback:function ($$v) {_vm.showModalDetails=$$v},expression:"showModalDetails"}},[_c('SystemDetails',{key:_vm.dataDetails.id,attrs:{"data":_vm.dataDetails},on:{"updatedTable":function($event){return _vm.updateTable()},"close":function($event){_vm.showModalDetails = false}}})],1),_c(VDialog,{attrs:{"width":"550px"},model:{value:(_vm.showModalCreate),callback:function ($$v) {_vm.showModalCreate=$$v},expression:"showModalCreate"}},[_c('SystemModal',{on:{"updatedTable":function($event){return _vm.updateTable()},"close":function($event){_vm.showModalCreate = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }