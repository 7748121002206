<template>
  <v-card>
    <v-card-title class="d-flex justify-center mb-2">
      Detalhes
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="data.system"
        label="Nome do Sistema"
        outlined
        dense
      />

      <v-switch
        v-model="getStatus"
        label="Ativo?"
        color="success"
        class="mt-0 ml-2"
        inset
        dense
      />

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    getStatus: {
      get() {
        if (this.data.active === 'verdadeiro') {
          return true
        }

        return false
      },

      set(value) {
        this.data.active = value
      },
    },
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const body = {
        system: this.data.system,
        active: this.data.active === 'verdadeiro',
      }

      await axiosIns.put(`/api/v1/records/system/update/${this.data.id}`, body).then(() => {
        this.showMessage({ title: 'Sucesso', text: 'Atualizado com sucesso', icon: 'success' })
      }).catch(() => {
        this.showMessage({ title: 'Erro ao enviar dados', text: 'contate a equipe de desenvolvimento', icon: 'error' })
      }).finally(() => {
        this.isLoading = false
        this.closeModal()
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
